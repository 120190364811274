import { ReduxActionModel } from "common/types";
import {
  SET_LOCATION_VALIDATION_CONFIG,
  UPDATE_VERIFICATION_LOCATION_ADDRESS,
  UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES,
  UPDATE_VERIFICATION_LOCATION_COORDINATES,
} from "../actions/types";

const initialState = {
  verificationLocationValidationConfig: null,
  verificationAddress: null,
  verificationTaskLocationCoordinates: null,
  verificationOfficerLocationCoordinates: null,
};

export default (state = initialState, action: ReduxActionModel) => {
  switch (action.type) {
    case SET_LOCATION_VALIDATION_CONFIG:
      state = {
        ...state,
        verificationLocationValidationConfig: action.payload,
      };
      break;
    case UPDATE_VERIFICATION_LOCATION_ADDRESS:
      state = {
        ...state,
        verificationAddress: action.payload,
      };
      break;
    case UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES:
      state = {
        ...state,
        verificationTaskLocationCoordinates: action.payload,
      };
      break;
    case UPDATE_VERIFICATION_LOCATION_COORDINATES:
      state = {
        ...state,
        verificationOfficerLocationCoordinates: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};
