import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ReduxStoreModel } from "../../../types";
import Sidebar from "./Sidebar";
import TopNav from "./TopNav";

const { Content } = Layout;

interface IFVOAuthShellProps {
  children: JSX.Element;
}
export function FVOAuthShell(props: IFVOAuthShellProps) {
  const history = useHistory();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const user = useSelector((store: ReduxStoreModel) => store.user);

  useEffect(() => {
    if (!user || !user.data || !user.data.access_token) {
      history.push(`/login?next=${location.pathname}${location.search}`);
    }
  }, [user, history]);

  return (
    <Layout>
      <Layout className="site-layout relative">
        <TopNav
          isSideBarOpen={isSidebarOpen}
          toggleSideBarOpen={setIsSidebarOpen}
        />
      </Layout>
      <div className="w-full">
        <Sidebar
          isOpen={isSidebarOpen}
          onClose={() => {
            setIsSidebarOpen(false);
          }}
        />
        <Content className="relative bg-white h-screen overflow-y-scroll">
          {props.children}
        </Content>
      </div>
    </Layout>
  );
}
