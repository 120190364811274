export const VERIFICATION_TASKS_HISTORY_FETCHED =
  "VERIFICATION_TASKS_HISTORY_FETCHED";

export const SET_LOCATION_VALIDATION_CONFIG = "SET_LOCATION_VALIDATION_CONFIG";

export const UPDATE_VERIFICATION_LOCATION_ADDRESS =
  "UPDATE_VERIFICATION_LOCATION_ADDRESS";

export const UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES =
  "UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES";
export const UPDATE_VERIFICATION_LOCATION_COORDINATES =
  "UPDATE_VERIFICATION_LOCATION_COORDINATES";
