import { createTelemetry } from '@web-platform/telemetry';
import { appConfig } from 'common/config/app-config';
import { useEffect } from 'react';

const telemetry = createTelemetry({
  collectorBaseUrl: appConfig.TELEMETRY_BASE_URL,
  serviceName: appConfig.TELEMETRY_SERVICE_NAME,
  showInBrowserConsole: appConfig.TELEMETRY_IN_DEBUG_MODE,
  devMode: appConfig.TELEMETRY_IN_DEV_MODE,
  environment: appConfig.ENVIRONMENT,
  disable: !appConfig.TELEMETRY_ENABLE,
});

export const { logging, metrics, tracing } = telemetry;

export const logger = logging.setup('logs');

export function useSetupWebVitalsAndTracing() {
  useEffect(() => {
    metrics.setupWebVitals();
    tracing.setup();
  }, []);
}
