import { Dispatch } from "redux";
import {
  clearData,
  gSuiteLogin,
  isUnregisteredDeviceError,
  login,
  loginWithToken,
  logout,
  storeUserDetails,
  storeUserToken,
} from "../../../common/services";
import Language from "../../../common/utils/language/en";
import { LoggedInUserDetails } from "../types/session-model";
import {
  USER_LOGIN_ATTEMPT,
  USER_LOGIN_FAILED,
  USER_LOGIN_SUCCESSFUL,
  USER_LOGOUT,
} from "./types";

export const generateErrorMessage = (error: any) => {
  return typeof error == "string"
    ? error
    : error?.responseMessage || error?.message || error?.error_description;
};

/* ==== action creators ==== */

const loggingIn = () => ({
  type: USER_LOGIN_ATTEMPT,
});

const loginSuccessful = (payload: LoggedInUserDetails): any => ({
  type: USER_LOGIN_SUCCESSFUL,
  payload,
});

const loginfailed = (payload: string) => ({
  type: USER_LOGIN_FAILED,
  payload,
});

/* ==== action creators ==== */

const persistUserDetails = (
  userData: LoggedInUserDetails,
  dispatch: Dispatch
) => {
  if (!userData.access_token) {
    dispatch(loginfailed(Language.LoginPage.loginError));
    return;
  }
  userData = { ...userData };
  storeUserToken(userData.access_token);
  storeUserDetails(userData);
  dispatch(loginSuccessful(userData));
};

const logUserIn =
  (username: string, password: string) => (dispatch: Dispatch) => {
    dispatch(loggingIn());

    login(username, password)
      .then((response: LoggedInUserDetails) => {
        persistUserDetails(response, dispatch);
      })
      .catch((error) => {
        if (error) {
          if (isUnregisteredDeviceError(error)) {
            error = error.response;
          } else {
            error = generateErrorMessage(error);
          }
        } else {
          error = Language.NetworkErrorMessage.errorMessage;
        }
        dispatch(loginfailed(error));
      });
  };

const loginWithMoniepointToken = (token: string) => (dispatch: Dispatch) => {
  dispatch(loggingIn());
  loginWithToken(token)
    .then((response: LoggedInUserDetails) => {
      persistUserDetails(response, dispatch);
    })
    .catch((error) => {
      if (error) {
        if (isUnregisteredDeviceError(error)) {
          error = error.response;
        } else {
          error = generateErrorMessage(error);
        }
      } else {
        error = Language.NetworkErrorMessage.errorMessage;
      }
      dispatch(loginfailed(error));
    });
};

const logUserOut = () => (dispatch: Dispatch) => {
  clearData();

  dispatch({
    type: USER_LOGOUT,
    payload: logout(),
  });
};

const gSuiteSSOLogin =
  (code: string) =>
  (dispatch: Dispatch): Promise<any> =>
    new Promise((resolve, reject) => {
      dispatch(loggingIn());
      gSuiteLogin(code)
        .then((response: LoggedInUserDetails) => {
          if (response.authorities && response.access_token) {
            persistUserDetails(response, dispatch);
          } else {
            throw Error(
              "Sorry, service failed to process your request please try again"
            );
          }
        })
        .catch((error) => {
          if (error) {
            error = generateErrorMessage(error);
          } else {
            error = Language.NetworkErrorMessage.errorMessage;
          }
          dispatch(loginfailed(error));
          reject(error);
        });
    });

export {
  logUserIn,
  logUserOut,
  persistUserDetails,
  gSuiteSSOLogin,
  loginWithMoniepointToken,
};
