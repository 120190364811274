import { GetVerificationLocationConfigResponse } from "modules/verificationTasks/pendingTasks/types";
import { ILocationConfig, ILocationCoordinates } from "../../types/components";

export interface ILocationData {
  coords: GeolocationCoordinates | null;
  timestamp: number;
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  errorMessage: string;
}

export const getGeolocation = (callBack: (data: ILocationData) => void) => {
  let locationData: ILocationData = {
    coords: null,
    timestamp: 0,
    errorMessage: "Location information is unavailable",
    isGeolocationAvailable: false,
    isGeolocationEnabled: false,
  };

  const showCurrentPosition = ({ coords, timestamp }: GeolocationPosition) => {
    locationData = {
      ...locationData,
      coords,
      timestamp,
      errorMessage: "",
      isGeolocationEnabled: true,
    };
    console.log({
      locationData,
    });

    callBack(locationData);
  };

  const showPositionError = (error: GeolocationPositionError) => {
    const { code, PERMISSION_DENIED, POSITION_UNAVAILABLE, TIMEOUT } = error;
    console.log({
      locationError: error,
    });
    switch (code) {
      case PERMISSION_DENIED:
        locationData = {
          ...locationData,
          errorMessage: "User denied the request for Geolocation.",
        };
        break;
      case POSITION_UNAVAILABLE:
        locationData = {
          ...locationData,
          errorMessage: "Location information is unavailable.",
        };
        break;
      case TIMEOUT:
        locationData = {
          ...locationData,
          errorMessage: "The request to get user location timed out.",
        };
        break;
      default:
        locationData = {
          ...locationData,
          errorMessage: "An unknown error occurred.",
        };
        break;
    }

    callBack(locationData);
  };
  const MoniepointAppFunction = window["MoniepointAppFunction"];

  if (
    typeof MoniepointAppFunction !== "undefined" &&
    "getCurrentLocation" in MoniepointAppFunction
  ) {
    locationData = { ...locationData, isGeolocationAvailable: true };
    const locationCacheTimeConfig = 60000;

    onMoniepointLocationCallback = (moniepointAppGeolocationPosition: any) => {
      if (moniepointAppGeolocationPosition) {
        console.log(moniepointAppGeolocationPosition);
        const locationData = JSON.parse(moniepointAppGeolocationPosition);
        showCurrentPosition(locationData);
      }
    };
    MoniepointAppFunction.getCurrentLocation(locationCacheTimeConfig);
  } else if ("geolocation" in navigator) {
    console.log("geolocation in navigator");
    locationData = { ...locationData, isGeolocationAvailable: true };
    const options = {
      enableHighAccuracy: true,
    };
    navigator.geolocation.getCurrentPosition(
      showCurrentPosition,
      showPositionError,
      options
    );
  } else {
    callBack(locationData);
  }
};

export const isLocationDistanceValid = (
  currentLongitude: number,
  currentLatitude: number,
  refLocationLongitude: number,
  refLocationLatitude: number,
  maximumDistance: number
): boolean => {
  const distanceBetweenLocations = getDistanceBetweenTwoLocations(
    currentLongitude,
    currentLatitude,
    refLocationLongitude,
    refLocationLatitude
  );
  console.log({
    distanceBetweenLocations,
  });
  return distanceBetweenLocations <= maximumDistance;
};
export const getDistanceBetweenTwoLocations = (
  currentLongitude: number,
  currentLatitude: number,
  refLocationLongitude: number,
  refLocationLatitude: number
): number => {
  console.log({
    currentLongitude,
    currentLatitude,
    refLocationLatitude,
    refLocationLongitude,
  });
  if (
    refLocationLatitude == currentLatitude &&
    refLocationLongitude == currentLongitude
  ) {
    return 0;
  } else {
    let radLat1 = (Math.PI * currentLatitude) / 180;
    let radLat2 = (Math.PI * refLocationLatitude) / 180;
    let theta = currentLongitude - refLocationLongitude;
    let radTheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radLat1) * Math.sin(radLat2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515; // distance in statute miles
    dist = dist * 1.609344 * 1000; // convert distance to meters
    return dist;
  }
};

type ValidateVerificationLocationConfigOptions = {
  locationValidationConfig: GetVerificationLocationConfigResponse;
  onLocationFetchFailed: (errorMessage?: string) => void;
  onLocationValidationFailed?: () => void;
  onLocationValidationPassed: (currentLocation: ILocationCoordinates) => void;
  verificationAddressCoordinates?: ILocationConfig;
};

export const validateVerificationLocationConfig = ({
  locationValidationConfig,
  onLocationValidationFailed,
  onLocationValidationPassed,
  onLocationFetchFailed,
  verificationAddressCoordinates,
}: ValidateVerificationLocationConfigOptions) => {
  let currentLocation = {
    longitude: 0,
    latitude: 0,
  };

  getGeolocation(({ coords, errorMessage }) => {
    if (errorMessage || !coords?.longitude || !coords?.latitude) {
      onLocationFetchFailed(errorMessage);
      return;
    }

    currentLocation = {
      longitude: coords.longitude,
      latitude: coords.latitude,
    };

    const shouldValidateLocation =
      locationValidationConfig.mediaCaptureLocationValidationRequired &&
      locationValidationConfig.allowedMediaCaptureRadiusInMeters &&
      onLocationValidationFailed;

    if (shouldValidateLocation) {
      const isLocationValid = (() => {
        if (
          !verificationAddressCoordinates ||
          !verificationAddressCoordinates.longitude ||
          !verificationAddressCoordinates.latitude ||
          locationValidationConfig.requireApproval
        ) {
          return true;
        }

        return isLocationDistanceValid(
          currentLocation.longitude,
          currentLocation.latitude,
          verificationAddressCoordinates.longitude,
          verificationAddressCoordinates.latitude,
          locationValidationConfig.allowedMediaCaptureRadiusInMeters
        );
      })();
      if (!isLocationValid) {
        onLocationValidationFailed();
        return;
      }
    }

    onLocationValidationPassed(currentLocation);
  });
};
