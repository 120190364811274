import { Environment } from "./type";

const devConfig = {
    TELEMETRY_IN_DEBUG_MODE: true,
    TELEMETRY_IN_DEV_MODE: true,
    TELEMETRY_ENABLE: false,
}

const stagingConfig = {
    TELEMETRY_IN_DEBUG_MODE: true,
    TELEMETRY_IN_DEV_MODE: true,
    TELEMETRY_ENABLE: true,
}

const prodConfig = {
    TELEMETRY_IN_DEBUG_MODE: false,
    TELEMETRY_IN_DEV_MODE: false,
    TELEMETRY_ENABLE: true,
}

const configMap = {
    dev: devConfig,
    test: devConfig,
    playground: devConfig,
    development: devConfig,
    staging: stagingConfig,
    production: prodConfig,
}

const environment = (process.env.REACT_APP_ENV as Environment) || 'development';

const commonConfig = {
    ENVIRONMENT: environment,
    TELEMETRY_SERVICE_NAME: 'fvs-frontoffice-web',
    TELEMETRY_BASE_URL: 'https://hermes-ingest.moniepointinc.com/v1',
}

const mappedConfig = configMap[environment];

export const appConfig = { ...commonConfig, ...mappedConfig }
